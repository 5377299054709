import React from 'react';
import ReactTerminal, {ReactThemes} from 'react-terminal-component';
import { ReactOutputRenderers } from 'react-terminal-component';
import {
    EmulatorState, OutputFactory, CommandMapping, FileSystem,
    Outputs, defaultCommandMapping
} from 'javascript-terminal';

function App() {
    const PAPER_TYPE = 'paper';

    const paperStyles = {
        backgroundColor: 'white',
        color: 'black',
        fontFamily: 'sans-serif',
        padding: '1em',
        margin: '1em 0',
        borderRadius: '0.2em'
    };

    const createPaperRecord = (title, body) => {
        return new OutputFactory.OutputRecord({
            type: PAPER_TYPE,
            content: {
                title,
                body
            }
        });
    };
    const PaperOutput = ({ content }) => (
        <div style={paperStyles}>
            <h1>{content.title}</h1>

            {content.body}
        </div>
    );

    const customState = EmulatorState.create({
        'fs': FileSystem.create({
            '/academic': { },
            '/work/README': {content: 'This is a text file'},
            '/hobbies/nested/directory': {},
            '/home/nested/directory/file': {content: 'End of nested directory!'}
        }),
        'commandMapping': CommandMapping.create({
            ...defaultCommandMapping,
            'print': {
                'function': (state, opts) => {
                    const userInput = opts.join(' ');

                    return {
                        output: createPaperRecord('A custom renderer', userInput)
                    };
                },
                'optDef': {}
            },
            'help': {
                'function': (state, opts) => {
                    const userInput = opts.join(' ');

                    return {
                        output: createPaperRecord('A custom renderer', userInput)
                    };
                },
                'optDef': {}
            }
        })
    });
    const defaultOutputs = customState.getOutputs();

    const newOutputs = Outputs.addRecord(
        defaultOutputs, OutputFactory.makeTextOutput(
            `## Hi I'm Eduardo Tamaki.\n`
            +`## This is a fun way to present myself!\n`
            +`##\n`
            +`## If you are not familiar with terminal please go to my Linkedin:\n`
            +`## https://www.linkedin.com/in/etamaki/\n`
            +`##\n`
            +"## Or type 'help' and hit return to learn how to explore.\n\n\n"
        )
    );
    const emulatorState = customState.setOutputs(newOutputs);

    const emulatorTheme = {
        ...ReactThemes.dye,height: '100%',
        width: '100%',
        overflow: 'scroll',
        marginBottom: 20,
        promptSymbolColor: '#3aff00',
        background: '#272938'
    }

    return (
    <div >
      <ReactTerminal
          theme={emulatorTheme}
          emulatorState={emulatorState}
          promptSymbol='eduardotamaki>'
          outputRenderers={{
              ...ReactOutputRenderers,
              [PAPER_TYPE]: PaperOutput
          }}
      />
    </div>
  );
}

export default App;
